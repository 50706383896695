import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Breadcrumbs, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'
import './Imprensa.style.scss'
import ReactPaginate from 'react-paginate';

import config from '../../helpers/config';

import Header from '../../components/Header/Header.component';
import Footer from '../../components/Footer/Footer.component';
import MenuRapido from '../../components/MenuRapido/MenuRapido.component';
import ArticlePesquisa from '../../components/ArticlePesquisa/ArticlePesquisa.component';

import BannerImprensa from '../../images/images/banner/banner_imprensa.jpg' 

import doGet from '../../helpers/request';
  
const sortByDescending = (arr, prop) => {
    return arr.sort((a, b) => toDate(b[prop]) - toDate(a[prop]));
}

function toDate(dateStr) {
    if(dateStr && dateStr.includes("/")) {
        let parts = dateStr.split("/")
        return new Date(parts[2], parts[1] - 1, parts[0]);
    } else if(dateStr && dateStr.includes("-")) {
        let parts = dateStr.split("-");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    } else {
        return new Date();
    }
}

const imprensaInitState = [
    {
        id: "",
        novidade_type: "banner-redirect",
        image: "",
        publish_date: "",
        link: "",
        author: "",
        category: "",
        title: "",
        title_text: "",
        original_text: "",
        createdAt: "",
        updatedAt: ""
    },
]

const initPagination = {
    data: imprensaInitState,
    offset: 0,
    numberPerPage: 5,
    pageCount: 0,
    currentData: []
}

const Imprensa = () => {
    let history = useHistory();
    const location = useLocation();
    const [ imprensa, setArrayImprensa ] = useState(imprensaInitState);
    const [ stateLoaded, setStateLoaded ] = useState(false);
    const [ pagination, setPagination ] = useState(initPagination);

    useEffect(() => {
        const getAPIData = async () => {
            setArrayImprensa(await doGet(`${config.host}/novidades/?full=true`));
        }
        getAPIData();
        setStateLoaded(true);
    }, [])

    useEffect(() => {
        setPagination((prevState) => ({
            ...prevState,
            pageCount: imprensa.filter(el => el.novidade_type === "imprensa").length / prevState.numberPerPage,
            data: imprensa.filter(el => el.novidade_type === "imprensa"),
            currentData: sortByDescending(imprensa, 'publish_date').filter(el => el.novidade_type === "imprensa").slice(pagination.offset, pagination.offset + pagination.numberPerPage),
        }))
    }, [pagination.numberPerPage, pagination.offset, imprensa])
    
    const handlePageClick = event => {
        const selected = event.selected;
        const offset = selected * pagination.numberPerPage
        setPagination({ ...pagination, offset })
    }

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClick = (path) => {
        history.push(path)
    }

    return (
        <main>
            <Header />
            <MenuRapido />
            <section className="imprensa-container">
                <div className="div-imprensa-container-bg">
                    <div className="div-imprensa-container">
                        <Breadcrumbs aria-label="breadcrumb" id="breadcrumb-container" separator="›">
                            <a color="inherit" href="/" onClick={() => handleClick("/")} className="breadcrumb-link">
                                <HomeIcon id="breadcrumb-home-icon"  />
                            </a>
                            <a color="inherit" href="/imprensa" onClick={() => handleClick("/imprensa")} className="breadcrumb-link">
                                Imprensa
                            </a>
                        </Breadcrumbs>
                        <center>
                            <img src={BannerImprensa} className='img-banner-imprensa' alt="Imprensa"/>
                            <Typography>
                            A Rede de Hospitais São Camilo de São Paulo conta com uma agência especializada para atendimentos de assessoria de imprensa.<br/><br/>
                            <b>Agência Contatto</b><br/><br />
                            <b>Paulo Ariel:</b> <br />
                            <a href="mailto:paulo.ariel@agenciacontatto.com.br">paulo.ariel@agenciacontatto.com.br</a><br />
                            <a href="tel:+5512991955950">(12)99195-5950</a><br/><br />
                            <b>Vinicius Cavallero:</b><br />
                            <a href="mailto:vinicius.cavallero@agenciacontatto.com.br">vinicius.cavallero@agenciacontatto.com.br</a><br />
                            <a href="tel:+5511910465599">(11) 91046-5599</a><br/><br />
                            <b>Luiz Conde:</b><br />
                            <a href="mailto:luiz.conde@agenciacontatto.com.br">luiz.conde@agenciacontatto.com.br</a><br />
                            <a href="tel:+5511910466145">(11) 91046-6145</a><br/>
                            <hr/> 
                            
                            </Typography>
                        </center>
                        {
                            stateLoaded ?
                                pagination.currentData.filter(imprensa =>
                                    (imprensa.novidade_type === 'imprensa')).map(novidade =>
                                        <ArticlePesquisa dados={novidade} />
                                    )
                            : ''
                        }
                        <div id="footer-table-page-imprensa">
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                pageCount={pagination.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={true}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    )
}

export default Imprensa